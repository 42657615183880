[data-column-id="name"].rdt_TableCol,
[data-column-id="no"].rdt_TableCol,
[data-column-id="programme"].rdt_TableCol,
[data-column-id="training"].rdt_TableCol,
[data-column-id="place"].rdt_TableCol,
[data-column-id="period"].rdt_TableCol,
[data-column-id="action"].rdt_TableCol,
[data-column-id="programme"].rdt_TableCol,
[data-column-id="trainee"].rdt_TableCol {
  background-color: #f4f7fc;
  padding: 12px 0px;
  font-size: 14px;
  font-weight: 600;
  /* opacity: "0.75"; */
}

.rdt_TableRow {
  padding: 12px 0px;
  font-size: 14px;
}

.rdt_TableRow:hover {
  cursor: pointer;
  background-color: #f4f7fc;
}

html,
body {
  height: "100%";
}

.payment {
  border: 1px solid #f2f2f2;
  height: 280px;
  border-radius: 20px;
  background: #fff;
}
.payment_header {
  background: #468c32;
  padding: 20px;
  border-radius: 20px 20px 0px 0px;
}

.check {
  margin: 0px auto;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: #fff;
  text-align: center;
}

.check i {
  vertical-align: middle;
  line-height: 50px;
  font-size: 30px;
}

.content {
  text-align: center;
}

.content h1 {
  font-size: 25px;
  padding-top: 25px;
}

.content a {
  width: 200px;
  height: 35px;
  color: #fff;
  border-radius: 30px;
  padding: 5px 10px;
  background: #468c32;
  transition: all ease-in-out 0.3s;
}

.content a:hover {
  text-decoration: none;
  background: #000;
}

.table-training {
  padding: 10px;
  width: 100%;
}

.table-list {
  list-style-type: none;
  word-wrap: break-word;
}

.custom-file-input {
  color: transparent;
  width: 154px;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input::before {
  width: auto;
  color: black;
  content: "Upload XLSX";
  display: inline-block;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #33945f;
  border-radius: 8px;
  padding: 10px 32px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);
  font-weight: 700;
  font-size: 14px;
}
.custom-file-input:hover::before {
  background-color: #33945f;
  color: white;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.custom-image-input {
  color: transparent;
  width: 158px;
}
.custom-image-input::-webkit-image-upload-button {
  visibility: hidden;
}
.custom-image-input::before {
  width: auto;
  color: black;
  content: "Upload Image";
  display: inline-block;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #33945f;
  border-radius: 8px;
  padding: 5px 32px;
  /* margin-top: 20px; */
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
}
.custom-image-input:hover::before {
  background-color: #33945f;
  color: white;
}
.custom-image-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

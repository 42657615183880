.App {
  text-align: center;
}

* {
  font-family: "Mulish", sans-serif;
  font-weight: 500;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* custom scrollbar for Chrome, Edge, Safari */
::-webkit-scrollbar {
  width: 12px;
}

.hide {
  -webkit-animation: fadeinout 1s linear forwards;
  animation: fadeinout 1s linear forwards;
}

@-webkit-keyframes fadeinout {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeinout {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}

.show {
  -webkit-animation: display 1s linear forwards;
  animation: display 1s linear forwards;
}

@-webkit-keyframes display {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

@keyframes display {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

/* Track */
::-webkit-scrollbar-track {
  background: lightgray;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #33945f;
  border-radius: 10px;
}

a:hover {
  text-decoration: underline;
}

.fileLabel:hover {
  cursor: pointer;
}

.powerBI-embed {
  height: 700px;
}

.certEditor {
  position: absolute !important;
  top: 0;
  left: 0;
  margin: 0 !important;
  height: 100vh !important;
  width: 100vw !important;
}

.certEditorFrame {
  display: flex !important;
  height: 100vh !important;
  width: 100vw !important;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
